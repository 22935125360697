import axios from 'axios';
import { Notify } from 'quasar';
import { CONST } from '@/config/config';
import { auth } from '@/shared/auth';
import app from '@/main';

axios.defaults.baseURL = CONST.API_BASE_URL;
axios.defaults.headers.common['x-api-key'] = CONST.API_KEY;
// 아래값을 default에 추가하면 os api와 통신할때 CORS오류가 발생함
// axios.defaults.headers.common['Poing-Authorization-Key'] =
//   CONST.POING_AUTHORIZATION_KEY;
// axios.defaults.headers.common['Poing-Authorization-Name'] =
//   CONST.POING_AUTHORIZATION_NAME;

axios.interceptors.request.use(
  (config) => {
    config.headers.common['Poing-Authorization-Key'] =
      CONST.POING_AUTHORIZATION_KEY;
    config.headers.common['Poing-Authorization-Name'] =
      CONST.POING_AUTHORIZATION_NAME;
    // config.headers.common['x-auth-token'] = `${auth.getAccessToken()}`;
    config.headers.platform = 'poing_web';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.data.error &&
      response.data.error.code === 999
    ) {
      app.$router.push({ name: 'system' }).catch((e) => {
        //
      });
    }
    return response;
  },
  (error) => {
    // 400 error
    if (error.response) {
      return Promise.reject(error.response);
    }
  }
);

export default axios;
