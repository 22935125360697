import Vue from 'vue';
import Vuex from 'vuex';
import { getModule } from 'vuex-module-decorators';
import PlaceModule from '@/store/place';
import PaymentModule from '@/store/payment';
import ReservationModule from '@/store/reservation';
import TicketModule from '@/store/ticket';
import StaticwebModule from '@/store/staticweb';
import BannerModule from '@/store/banner';
import SearchModule from '@/store/search/index';
import ReviewModule from '@/store/review';
import LikeModule from '@/store/like';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    place: PlaceModule,
    payment: PaymentModule,
    reservation: ReservationModule,
    ticket: TicketModule,
    staticweb: StaticwebModule,
    banner: BannerModule,
    search: SearchModule,
    review: ReviewModule,
    like: LikeModule
  }
});

export const placeModule = getModule(PlaceModule, store);
export const paymentModule = getModule(PaymentModule, store);
export const reservationModule = getModule(ReservationModule, store);
export const ticketModule = getModule(TicketModule, store);
export const staticwebModule = getModule(StaticwebModule, store);
export const bannerModule = getModule(BannerModule, store);
export const searchModule = getModule(SearchModule, store);
export const reviewModule = getModule(ReviewModule, store);
export const likeModule = getModule(LikeModule, store);

export default store;
