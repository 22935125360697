import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from '@/config/axios';
import { CONST } from '@/config/config';
import { PlaceResponse, PersonDetailResponse } from '@/store/place/types';
import moment from 'moment';
import { unionWith } from 'lodash';

@Module({ name: 'place', namespaced: true })
export default class PlaceModule extends VuexModule {
  public poingApi: string = 'https://poing-api-qa.mypoing.com/api';
  public placeData: PlaceResponse | null = null;
  public placeImageData: any = null;
  public placeReview: any = null;
  public otherProductData: any | null = null;
  public placeMenuData: any | null = null;
  public placeProductData: any | null = null;
  public placeTimeData: any | null = null;
  public privacyPlaceListData: any | null = null;
  public noticeData: any | null = null;
  public personDetailData: PersonDetailResponse | null = null;

  @Mutation
  public setPlaceData(response: PlaceResponse) {
    this.placeData = response;
  }

  @Mutation
  public setPlaceImage(response: any) {
    this.placeImageData = response;
  }

  @Mutation
  public setPlaceReview(response: any) {
    this.placeReview = response;
  }

  @Mutation
  public setOtherProduct(response: any) {
    this.otherProductData = response;
    // this.otherProductData = response;
    // this.otherProductData.data = filter(this.otherProductData.data, (row) => {
    //   return row.id !== app.$route.params.productId;
    // });
  }

  @Mutation
  public setPlaceMenu(response: any) {
    this.placeMenuData = response;
  }
  @Mutation
  public setPlaceProduct(response: any) {
    this.placeProductData = response;
  }
  @Mutation
  public setPlaceTime(response: any) {
    this.placeTimeData = response;
  }
  @Mutation
  public setPrivacyPlaceList(response: any) {
    this.privacyPlaceListData = response;
  }
  @Mutation
  public setNotice(response: any) {
    if (response.meta.offset === 1) {
      this.noticeData = null;
    }
    // 데이터가 없는 초기값
    if (!this.noticeData || !this.noticeData.data.length) {
      this.noticeData = response;
    } else {
      // 데이터가 있는 경우 기존과 병합
      this.noticeData.data = unionWith(
        this.noticeData.data,
        response.data,
        (a: any, b: any) => {
          return a.id === b.id;
        }
      );
      // meta
      this.noticeData.meta = response.meta;
    }
  }
  @Mutation
  public setPersonDetail(response: PersonDetailResponse) {
    this.personDetailData = response;
  }

  // 매장 정보 조회
  @Action
  public async fetchPlaceInfoData(id: string) {
    try {
      const res: any = await axios.get(`/place/${id}`);
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceData', res.data);
      }
      return res.data;
    } catch (e) {
      return e;
    }
  }

  // 매장 이미지 조회
  @Action
  public async fetchPlaceImage(id: string) {
    try {
      const res = await axios.get(`place/${id}/image`);
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceImage', res.data);
      }
    } catch (e) {
      return e;
    }
  }

  // 매장 리뷰 조회
  @Action
  public async fetchPlaceReview(params) {
    try {
      const res = await axios.get('/review', {
        params
      });
      if (res && res.data) {
        this.context.commit('setPlaceReview', res.data);
      }
      return Promise.resolve(res && res.data);
    } catch (error) {
      console.log(error);
    }
  }
  // 같은 매장의 다른 티켓
  @Action
  public async fetchOtherProduct(params: any) {
    try {
      const res = await axios.get(`/place/${params.placeId}/product`, {
        params: {
          except_product_id: params.productId
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setOtherProduct', res.data);
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }
  // 매장 상세 메뉴
  @Action
  public async fetchPlaceMenu(placeId: any) {
    try {
      const res = await axios.get(`/place/${placeId}/menu`);
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceMenu', res.data);
      }
    } catch (error) {
      return null;
    }
  }
  // 매장내 티켓
  @Action
  public async fetchPlaceProduct(placeId: any) {
    try {
      const res = await axios.get(`/place/${placeId}/product`);
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceProduct', res.data);
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }
  // 매장 상세 타임블럭
  @Action
  public async fetchPlaceTime(placeId: any) {
    try {
      const res = await axios.get(`/place/${placeId}/timeslot`, {
        params: {
          date: moment().format('YYYY-MM-DD'),
          person: 2
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceTime', res.data);
      }
    } catch (error) {
      return null;
    }
  }
  // 예약취소
  @Action
  public async deleteCancel(params) {
    try {
      const res = await axios.delete(`/reservation/${params.id}`, {
        params: {
          refund_rate: params.refund_rate
        }
      });
      return res.data;
    } catch (error) {
      return null;
    }
  }
  // 개인정보 동의 매장 리스트
  @Action
  public async fetchPrivacyPlaceList(params) {
    try {
      const res = await axios.get('/place', {
        params
      });
      if (res.data && res.data.status) {
        this.context.commit('setPrivacyPlaceList', res.data);
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }
  // 공지사항
  @Action
  public async fetchNotice(params: any) {
    try {
      const res = await axios.get('/notice', { params });
      if (res && res.data) {
        this.context.commit('setNotice', res.data);
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }

  // 인원구분 설정 정보
  @Action
  public async fetchPersonDetail(placeId: number) {
    try {
      const res = await axios.get(`/place/${placeId}/personDetail`);
      if (res && res.data) {
        this.context.commit('setPersonDetail', res.data);
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }
  // 이메일로 비밀번호 재설정
  @Action
  public async fetchEmailPasswordReset(payload: any) {
    try {
      const res = await axios.put('/user/updatePassword', payload);

      if (res && res.data && res.data.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }

  get placeInfoData() {
    if (!this.placeData) {
      return '';
    } else {
      return this.placeData.data;
    }
  }

  get placeImage() {
    if (!this.placeImageData) {
      return '';
    } else {
      return this.placeImageData.data;
    }
  }

  get placeReviewData() {
    if (!this.placeReview) {
      return '';
    } else {
      return this.placeReview.data;
    }
  }
  get otherProduct() {
    if (!this.otherProductData) {
      return '';
    } else {
      return this.otherProductData.data;
    }
  }
  get placeMenu() {
    if (!this.placeMenuData) {
      return '';
    } else {
      return this.placeMenuData.data;
    }
  }

  get placeMenuUnit() {
    if (!this.placeMenuData) {
      return '';
    } else {
      return this.placeMenuData.meta;
    }
  }
  get placeProduct() {
    if (!this.placeProductData) {
      return '';
    } else {
      return this.placeProductData.data;
    }
  }
  get placeTime() {
    if (!this.placeTimeData) {
      return '';
    } else {
      return this.placeTimeData.data;
    }
  }
  get privacyPlaceList() {
    if (!this.privacyPlaceListData) {
      return '';
    } else {
      return this.privacyPlaceListData.data;
    }
  }
  get notice() {
    if (!this.noticeData) {
      return '';
    }
    return this.noticeData;
  }

  get personDetail() {
    if (!this.personDetailData) {
      return '';
    }

    return this.personDetailData.data;
  }
}
