import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from '@/config/axios';
import app from '@/main';
import { AxiosResponse } from 'axios';
import { Notify } from 'quasar';
import { unionWith } from 'lodash';
import moment from 'moment';

@Module({ name: 'ticket', namespaced: true })
export default class TicketModule extends VuexModule {
  public diningTicketData: any | null = null;
  public diningGuideData: any | null = null;
  public productData: any | null = null;
  public productImageData: any | null = null;
  public productQnaData: any | null = null;
  public qnaRegisterData: any | null = null;
  public qnaDeleteData: any | null = null;
  // public productTimeData: any | null = null;
  public ableReservationDateData: any | null = null;

  @Mutation
  public setDiningTicket(response: any) {
    if (response.meta.offset === 1) {
      this.diningTicketData = null;
    }
    // 데이터가 없는 초기값
    if (!this.diningTicketData || !this.diningTicketData.data.length) {
      this.diningTicketData = response;
    } else {
      // 데이터가 있는 경우 기존과 병합
      this.diningTicketData.data = unionWith(
        this.diningTicketData.data,
        response.data,
        (a: any, b: any) => {
          return a.id === b.id;
        }
      );
      // meta
      this.diningTicketData.meta = response.meta;
    }
  }

  @Mutation
  public setDiningGuide(response: any) {
    if (response.meta.offset === 1) {
      this.diningGuideData = null;
    }
    // 데이터가 없는 초기값
    if (!this.diningGuideData || !this.diningGuideData.data.length) {
      this.diningGuideData = response;
    } else {
      // 데이터가 있는 경우 기존과 병합
      this.diningGuideData.data = unionWith(
        this.diningGuideData.data,
        response.data,
        (a: any, b: any) => {
          return a.id === b.id;
        }
      );
      // meta
      this.diningGuideData.meta = response.meta;
    }
  }

  @Mutation
  public setProduct(response: any) {
    this.productData = response;
  }

  @Mutation
  public setProductImage(response: any) {
    this.productImageData = response;
  }

  @Mutation
  public setProductQna(response: any) {
    if (response.meta.offset === 1) {
      this.productQnaData = null;
    }
    // 데이터가 없는 초기값
    if (!this.productQnaData || !this.productQnaData.data.length) {
      this.productQnaData = response;
    } else {
      // 데이터가 있는 경우 기존과 병합
      this.productQnaData.data = unionWith(
        this.productQnaData.data,
        response.data,
        (a: any, b: any) => {
          return a.id === b.id;
        }
      );
      // meta
      this.productQnaData.meta = response.meta;
    }
  }

  @Mutation
  public setQnaRegister(response: any) {
    this.qnaRegisterData = response;
  }

  @Mutation
  public setQnaDelete(response: any) {
    this.qnaDeleteData = response;
  }

  // @Mutation
  // public setProductTime(response: any) {
  //   this.productTimeData = response;
  // }
  @Mutation
  public setAbleReservationDate(response: any) {
    this.ableReservationDateData = response;
  }

  // 다이닝 티켓
  @Action
  public async fetchDiningTicket(payload: any) {
    try {
      const res = await axios.get('/product', {
        params: payload
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setDiningTicket', res.data);
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }
  // 다이닝 가이드
  @Action
  public async fetchDiningGuide(payload: any) {
    try {
      const res = await axios.get('/banner/guide', {
        params: payload
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setDiningGuide', res.data);
      }
    } catch (error) {
      return null;
    }
  }
  // 티켓 상세
  @Action
  public async fetchProduct(productId: any) {
    try {
      const res = await axios.get(`/product/${productId}`);
      if (res && res.data && res.data.data) {
        this.context.commit('setProduct', res.data);
        return res.data;
      }
      return res.data;
    } catch (error) {
      return null;
    }
  }
  // 티켓 상세 이미지
  @Action
  public async fetchProductImage(productId: any) {
    try {
      const res = await axios.get(`/product/${productId}/image`);
      if (res && res.data && res.data.data) {
        this.context.commit('setProductImage', res.data);
      }
    } catch (error) {
      return null;
    }
  }
  // 티켓 상세 QnA
  @Action
  public async fetchProductQna(params: any) {
    try {
      const res = await axios.get(`/product/${params.productId}/qna`, {
        params: {
          offset: params.offset,
          limit: params.limit
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setProductQna', res.data);
      }
    } catch (error) {
      return null;
    }
  }
  // 상품 문의 등록
  @Action
  public async fetchQnaRegister(payload: any) {
    try {
      const res = await axios.post('/qna', payload);
      if (res && res.data && res.data.data) {
        this.context.commit('setQnaRegister', res.data);
      }
    } catch (error) {
      return null;
    }
  }
  // 상품 문의 삭제
  @Action
  public async fetchQnaDelete(qnaId: any) {
    try {
      const res = await axios.delete(`/qna/${qnaId}`);
      if (res && res.data && res.data.data) {
        this.context.commit('setQnaDelete', res.data);
      }
    } catch (error) {
      return null;
    }
  }
  // // 티켓 상세 타임블럭
  // @Action
  // public async fetchProductTime(params: any) {
  //   try {
  //     const res = await axios.get(`/product/${params.id}/timeslot`, {
  //       params: {
  //         date: params.date,
  //         person: params.person
  //       }
  //     });
  //     if (res && res.data && res.data.data) {
  //       this.context.commit('setProductTime', res.data);
  //     }
  //   } catch (error) {
  //     return null;
  //   }
  // }
  // 예약 가능한 가장 빠른 날짜의 예약일 및 시간대 조회
  @Action
  public async fetchAbleReservationDate(params: any) {
    try {
      const res = await axios.get(`/reservation/exclusiveNearSchedule`, {
        params: {
          product_id: params.product_id
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setAbleReservationDate', res.data);
      }
    } catch (error) {
      return null;
    }
  }

  get diningTicketList() {
    if (!this.diningTicketData) {
      return '';
    } else {
      return this.diningTicketData;
    }
  }
  // get diningTicketMeta() {
  //   if (!this.diningTicketData) {
  //     return null;
  //   } else {
  //     return this.diningTicketData.meta;
  //   }
  // }

  get diningGuideList() {
    if (!this.diningGuideData) {
      return '';
    } else {
      return this.diningGuideData;
    }
  }
  // get diningGuideMeta() {
  //   if (!this.diningGuideData) {
  //     return '';
  //   } else {
  //     return this.diningGuideData.meta;
  //   }
  // }

  get productInfo() {
    if (!this.productData) {
      return '';
    } else {
      return this.productData.data;
    }
  }

  get placeInfo() {
    if (!this.productData) {
      return '';
    } else {
      return this.productData.data.place;
    }
  }

  get productImage() {
    if (!this.productImageData) {
      return '';
    } else {
      return this.productImageData.data;
    }
  }

  get productQna() {
    if (!this.productQnaData) {
      return '';
    } else {
      return this.productQnaData;
    }
  }

  // get productTime() {
  //   if (!this.productTimeData) {
  //     return '';
  //   } else {
  //     return this.productTimeData.data;
  //   }
  // }

  get ableReservationDate() {
    if (!this.ableReservationDateData) {
      return '';
    } else {
      return this.ableReservationDateData.data;
    }
  }
}
