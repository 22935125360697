
















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { bannerModule } from '@/store';
import { EventBus } from '@/config/config';
import { Notify } from 'quasar';
import * as firebase from 'firebase/app';

@Component
export default class MainHeader extends Vue {
  public title: string = this.$cookies.get('areaInfo') ? this.$cookies.get('areaInfo').service_title : '서울 전체';
  public geoOptions: any = {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity
  }
	public appBanner: boolean = this.$cookies.get('PoingAppBanner') === 'Y' ? false : true;

  // get user() {
  //   return authModule.user;
  // }

  public mounted() {
		if (this.$cookies.get('todayAppDownload') === 'Y') {
			if (this.$cookies.get('PoingAppBanner') === 'Y') {
				this.appBanner = false;
			} else {
				this.appBanner = true;
			}
		} else {
			this.appBanner = false;
		}
		if (this.$q.platform.is.desktop) {
			this.appBanner = false;
		}
    // EventBus.$on('updateName', (data) => {
    //   this.title = data;
    // });
    // EventBus.$on('inHome', () => {
    //   if (localStorage.nowLocation) {
    //   	const geoCode = localStorage.nowLocation.split(',');
    //   	const location = { lat: geoCode[0], lot: geoCode[1] };
    //   	bannerModule.fetchGPSArea(location).then((res: any) => {
		//   if (res && res.status) {
		//   	EventBus.$emit('updateLocation', res.data);
		//   	this.title = res.data.service_title;
		//   	setTimeout(() => {
		// 			this.eventSelectArea('default');
		// 				}, 1000);
		// 			} else {
		// 				this.setDefaultArea();
		// 			}
		// 		});
		// 	} else {
		// 		this.setDefaultArea();
		// 	}
		// });
  }

  public setDefaultArea() {
  	if (this.areaData) {
			this.title = this.areaData.default_area.display_title;
			this.$q.notify(`'${this.areaData.default_area.display_title}' (으)로 설정되었습니다.`);
		}
	}

	// public nowLocationLookUp() {
	// 	navigator.geolocation.getCurrentPosition(this.getLocation, this.setLocation);
	// }

  // public getLocation() {
  //   if (localStorage.nowLocation) {
  //     const geoCode = localStorage.nowLocation.split(',');
  //     const location = { lat: geoCode[0], lot: geoCode[1] };

  //     bannerModule.fetchGPSArea(location).then((res: any) => {
  //       if (res && res.status) {
  //         EventBus.$emit('updateLocation', res.data);
  //         this.title = res.data.service_title;
  //         this.eventSelectArea('auto');
  //       } else {
	// 				this.$q.notify('내 위치 조회에 실패 하였습니다.\n 이전 지역 설정이 유지됩니다.');
	// 			}
  //     });
  //   } else {
  //     this.setLocation();
  //   }
  // }
	public ticketGo() {
		if (
			this.$route.fullPath.includes('?') &&
			this.$route.name === 'ticket'
		) {
			const url = this.$route.fullPath;
			location.href = url;
		} else if (
			!this.$route.query.length &&
			this.$route.fullPath === '/ticket'
		) {
			return;
		}
		else {
			this.$router.push({name: 'ticket'});
		}
	}
	public appCloseClick() {
		const date = new Date();
		date.setHours(23, 59, 59, 999);
		this.$cookies.set('PoingAppBanner', 'Y', date);
		this.appBanner = false;
		this.$emit('update');
	}
	public moveAppStore() {
		window.location.href = 'https://poingapp.page.link/installApps';
	}

  get gpsArea(): any {
    return bannerModule.getGPSArea;
  }
  get banner(): any {
    return bannerModule.getBanner;
  }
	get areaData() {
		return bannerModule.getArea;
	}

  public eventSelectArea(type: string) {
    const eventName = 'select_area';
    const eventParams: any = {
      type: type,
      id: this.gpsArea.area_id,
      name: this.gpsArea.service_title,
      banner_group_count: this.banner && this.banner.length
    };
    firebase.analytics().logEvent(eventName, eventParams);
  }

  // public setLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(this.geoPosition, this.geoError, this.geoOptions);
  //   } else {
  //     alert('GPS를 지원하지 않습니다.');
  //   }
  // }
  // public geoPosition(position) {
  //   localStorage.nowLocation = position.coords.latitude + ',' + position.coords.longitude;
  //   this.getLocation();
  // }
  // pc : 사파리, 아이폰 : 브라우저 에서 오류로 인해 적용 안함
  public geoError(error) {
		this.$q.notify('권한이 없습니다. 설정에서 위치정보 수집을 허용하신 후 다시 시도해주세요.');
    // switch (error.code) {
    //   case 1 :
    //     this.$q.notify('위치 정보 동의 거절');
    //     break;
    //   case 2 :
    //     this.$q.notify('위치를 찾을 수 없습니다.');
    //     break;
    //   case 3 :
    //     this.$q.notify('요청 응답시간이 초과했습니다.');
    //     break;
    //   default :
    //     this.$q.notify('알수없는 오류가 발생');
    //     break;
    // }
    if (localStorage.nowLocation) localStorage.removeItem('nowLocation');
  }

	@Watch('isAppDownloadClick')
	public isAppDownloadClickUpdate() {
		if(this.isAppDownloadClick && !this.$q.platform.is.desktop) {
			this.appBanner = true;
		}
	}

	get isAppDownloadClick() {
		return bannerModule.isAppDownloadClick;
	}
}
