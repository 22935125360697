import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from '@/config/axios';
import app from '@/main';
import { AxiosResponse } from 'axios';
import { Notify } from 'quasar';

@Module({ name: 'staticweb', namespaced: true })
export default class StaticwebModule extends VuexModule {
  public staticContentsData: any | null = null;
  public mainPopupData: any | null = null;

  @Mutation
  public setStaticweb(response: any) {
    this.staticContentsData = response;
  }

  @Mutation
  public setMainPopup(response: any) {
    this.mainPopupData = response;
  }

  // 스태틱 페이지
  @Action
  public async fetchStaticweb(staticwebId: any) {
    try {
      const res = await axios.get(`/landing/${staticwebId}`);
      if (res.status) {
        this.context.commit('setStaticweb', res.data);
      }
      return res && res.data && res.data.data;
    } catch (e) {
      return null;
    }
  }
  // 전면 팝업
  @Action
  public async fetchMainPopup() {
    try {
      const res = await axios.get('/system/frontad');
      if (res.status) {
        this.context.commit('setMainPopup', res.data);
      }
      return res && res.data;
    } catch (e) {
      return null;
    }
  }

  get staticwebContents() {
    if (!this.staticContentsData) {
      return '';
    }
    return this.staticContentsData.data;
  }

  get mainPopup() {
    if (!this.mainPopupData) {
      return '';
    }
    return this.mainPopupData.data;
  }
}
