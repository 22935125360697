/* tslint:disable:no-console */

import { register } from 'register-service-worker';
import { CONST } from '@/config/config';
import packageJson from '../package.json';

if (process.env.NODE_ENV === 'production') {
  const cachedKey = 'poing-new-version__cached';
  // @ts-ignore
  window.poingVersionUpdate = (isRefresh?: boolean) => {
    try {
      localStorage.setItem(cachedKey, CONST.APP_VERSION);
    } catch {
      // ignore
    }
    if (isRefresh) {
      window.location.reload(true);
    }
  };
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
      console.log('배포완료 cached');
      // @ts-ignore
      window.poingVersionUpdate(true);
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
      console.log('배포완료');
      try {
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      } catch {
        // ignore
      }
      try {
        if (packageJson.version === localStorage.getItem(cachedKey)) {
          // 이미 업데이트 됨
          return;
        } else {
          const message = {
            command: 'clearCache',
            callback: `window.location.reload(true);`
          };
          (window as any).webkit.messageHandlers.osApp.postMessage(message);
        }
      } catch {
        // ignore
      }
      // @ts-ignore
      window.poingVersionUpdate(true);
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}
