import '@babel/polyfill';
import 'core-js';
import '@/assets/styles/quasar.scss';
import '@/assets/sass/app.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './config/quasar';
import './config/plugins';
import './config/classComponentHook';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: 'AIzaSyAi5uqBNo2-Vodiws9IgrqdAJ9jv3BHZwk',
  authDomain: 'poing-7506b.firebaseapp.com',
  databaseURL: 'https://poing-7506b.firebaseio.com',
  projectId: 'poing-7506b',
  storageBucket: 'poing-7506b.appspot.com',
  messagingSenderId: '128640856121',
  appId: '1:128640856121:web:68091dea66aaa9bc941de8',
  measurementId: 'G-327SFB5XK9'
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.prototype.$analytics = firebase.analytics();

const app = new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');

export default app;
