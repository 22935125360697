import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axiosOS from '@/config/axios_os';
import {
  ReservationDateResponse,
  ReservationResponse,
  ReservationTimeResponse
} from '@/store/payment/types';
import { Notify } from 'quasar';

@Module({ name: 'payment', namespaced: true })
export default class PaymentModule extends VuexModule {
  public reservationData: ReservationResponse | null = null;
  public reservationPossibleDate: ReservationDateResponse | null = null;
  public reservationPossibleNextDate: ReservationDateResponse | null = null;
  public reservationPossibleTime: ReservationTimeResponse | null = null;
  public payTypes: any | null = null;

  @Mutation
  public setReservationData(response: any) {
    this.reservationData = response;
  }

  @Mutation
  public setPayTypes(response: any) {
    this.payTypes = response;
  }

  @Mutation
  public setReservationDate(response: any) {
    this.reservationPossibleDate = response;
  }

  @Mutation
  public setReservationNextDate(response: any) {
    this.reservationPossibleNextDate = response;
  }

  @Mutation
  public setReservationTime(response: any) {
    this.reservationPossibleTime = response;
  }

  // 예약 정보 조회
  @Action
  public async fetchReservationSetting(id: string) {
    try {
      const res: any = await axiosOS.get(
        `/externals/deposits/reservations/${id}`
      );
      if (res && res.data.code === 20000) {
        this.context.commit('setReservationData', res.data);
      } else {
        Notify.create({
          message: res.data.message
        });
      }
      return res.data;
    } catch (error) {
      return error;
    }
  }

  // 결제 수단 리스트 조회
  @Action
  public async fetchPayTypes(id: string) {
    try {
      const res: any = await axiosOS.get(
        `/externals/deposits/reservations/${id}/pg-platforms`
      );
      if (res && res.data.code === 20000) {
        this.context.commit('setPayTypes', res.data);
      } else {
        Notify.create({
          message: res.data.message
        });
      }
      return res.data;
    } catch (error) {
      return error;
    }
  }

  // 결제 처리
  @Action
  public async payment(params: any) {
    const data = {
      ...params
    };
    delete data.reservation_id;
    try {
      const res: any = await axiosOS.post(
        `/externals/deposits/reservations/${params.reservation_id}/pay`,
        data
      );
      if (res && res.data.code === 20000) {
        return res.data;
      } else {
        Notify.create({
          message: res.data.message
        });
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  // 예약 가능한 날짜 조회
  @Action
  public async fetchReservePossibleDate(payload: any) {
    try {
      const res: any = await axiosOS.get(
        `/externals/deposits/reservations/${payload.reservation_id}/able-days?month=${payload.date}`
      );
      if (res && res.data.code === 20000) {
        this.context.commit('setReservationDate', res.data);
      } else {
        Notify.create({
          message: res.data.message
        });
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  // 예약 가능한 다음달 날짜 조회
  @Action
  public async fetchReservePossibleNextDate(payload: any) {
    try {
      const res: any = await axiosOS.get(
        `/externals/deposits/reservations/${payload.reservation_id}/able-days?month=${payload.date}`
      );
      if (res && res.data.code === 20000) {
        this.context.commit('setReservationNextDate', res.data);
      } else {
        Notify.create({
          message: res.data.message
        });
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  // 예약 가능한 시간 조회
  @Action
  public async fetchReservePossibleTime(payload: any) {
    try {
      const res: any = await axiosOS.get(
        `/externals/deposits/reservations/${payload.reservation_id}/able-times?day=${payload.date}`
      );
      if (res && res.data.code === 20000) {
        this.context.commit('setReservationTime', res.data);
      } else {
        Notify.create({
          message: res.data.message
        });
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  // 예약 변경
  @Action
  public async updateReservation(params: any) {
    const data = {
      ...params
    };
    delete data.reservation_id;
    try {
      const res: any = await axiosOS.patch(
        `/externals/deposits/reservations/${params.reservation_id}`,
        data
      );
      if (res && res.data.code === 20000) {
        return res.data;
      }
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  // 예약 취소
  @Action
  public async cancelReservation(id: string) {
    try {
      const res: any = await axiosOS.post(
        `/externals/deposits/reservations/${id}/cancel`
      );
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  get getReservationData() {
    if (!this.reservationData) {
      return '';
    } else {
      return this.reservationData.data;
    }
  }

  get getPayTypes() {
    if (!this.payTypes) {
      return '';
    } else {
      return this.payTypes.data;
    }
  }

  get getReservationDate() {
    if (!this.reservationPossibleDate) {
      return '';
    } else {
      return this.reservationPossibleDate.data;
    }
  }

  get getReservationNextDate() {
    if (!this.reservationPossibleNextDate) {
      return '';
    } else {
      return this.reservationPossibleNextDate.data;
    }
  }

  get getReservationTime() {
    if (!this.reservationPossibleTime) {
      return '';
    } else {
      return this.reservationPossibleTime.data;
    }
  }
}
