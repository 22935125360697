import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from '@/config/axios';
import app from '@/main';
import { AxiosResponse } from 'axios';
import { Notify } from 'quasar';
import {
  AutoCompleteResponse,
  FilterResponse,
  PlaceSearchResponse,
  PopularKeywordResponse,
  ProductSearchResponse
} from '@/store/search/types';
import { unionWith } from 'lodash';

@Module({ name: 'search', namespaced: true })
export default class SearchModule extends VuexModule {
  public filterData: FilterResponse | null = null;
  public autoCompleteData: AutoCompleteResponse | null = null;
  public placeSearchData: PlaceSearchResponse | null = null;
  public productSearchData: ProductSearchResponse | null = null;
  public popularKeyword: PopularKeywordResponse | null = null;

  @Mutation
  public setFilter(response: FilterResponse) {
    this.filterData = response;
  }

  @Mutation
  public setAutoComplete(response: AutoCompleteResponse) {
    this.autoCompleteData = response;
  }

  @Mutation
  public setPlaceSearchData(response: PlaceSearchResponse) {
    if (response.meta.offset === 1) this.placeSearchData = null;
    if (!this.placeSearchData || !this.placeSearchData.data.length) {
      this.placeSearchData = response;
    } else {
      // 데이터가 있는 경우 기존과 병합
      this.placeSearchData.data = unionWith(
        this.placeSearchData.data,
        response.data,
        (a: any, b: any) => {
          return a.id === b.id;
        }
      );
      // meta
      this.placeSearchData.meta = response.meta;
    }
  }

  @Mutation
  public setProductSearchData(response: ProductSearchResponse) {
    if (response.meta.offset === 1) this.productSearchData = null;
    if (!this.productSearchData || !this.productSearchData.data.length) {
      this.productSearchData = response;
    } else {
      // 데이터가 있는 경우 기존과 병합
      this.productSearchData.data = unionWith(
        this.productSearchData.data,
        response.data,
        (a: any, b: any) => {
          return a.id === b.id;
        }
      );
      // meta
      this.productSearchData.meta = response.meta;
    }
  }

  @Mutation
  public resetSearchData() {
    this.placeSearchData = null;
    this.productSearchData = null;
  }

  @Mutation
  public setPopularKeyword(response: PopularKeywordResponse) {
    this.popularKeyword = response;
  }

  // 필터 조회
  @Action
  public async fetchFilter(params) {
    try {
      const res: AxiosResponse<FilterResponse> = await axios.get(
        '/search/filter',
        { params }
      );
      if (res && res.data) {
        this.context.commit('setFilter', res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 검색어 자동 완성
  @Action
  public async fetchAutoComplete(text) {
    try {
      const res: AxiosResponse<AutoCompleteResponse> = await axios.get(
        '/place/autoComplete',
        { params: { keyword: text } }
      );
      if (res && res.data) {
        this.context.commit('setAutoComplete', res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 매장 검색
  @Action
  public async fetchPlaceSearch(params) {
    try {
      if (params) {
        const res: AxiosResponse<PlaceSearchResponse> = await axios.get(
          '/place',
          { params }
        );
        if (res && res.data) {
          this.context.commit('setPlaceSearchData', res.data);
          return res.data;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 티켓 검색
  @Action
  public async fetchProductSearch(params) {
    try {
      if (params) {
        const res: AxiosResponse<ProductSearchResponse> = await axios.get(
          '/product',
          { params }
        );
        if (res && res.data) {
          this.context.commit('setProductSearchData', res.data);
          return res.data;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 검색 초기화
  @Action
  public async resetSearch() {
    this.context.commit('resetSearchData');
  }

  // 인기 검색어 조회
  @Action
  public async fetchPopularKeyword() {
    try {
      const res: AxiosResponse<PopularKeywordResponse> = await axios.get(
        '/search/popularKeyword'
      );
      if (res && res.data) {
        this.context.commit('setPopularKeyword', res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  get getFilter() {
    if (!this.filterData) {
      return '';
    }
    return this.filterData.data;
  }

  get getAutoComplete() {
    if (!this.autoCompleteData) {
      return '';
    }
    return this.autoCompleteData.data;
  }

  get getPlaceSearch() {
    if (!this.placeSearchData) {
      return '';
    }
    return this.placeSearchData;
  }

  get getProductSearch() {
    if (!this.productSearchData) {
      return '';
    }
    return this.productSearchData;
  }

  get getPopularKeyword() {
    if (!this.popularKeyword) {
      return '';
    }
    return this.popularKeyword.data;
  }
}
