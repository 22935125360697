import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from '@/config/axios';
import app from '@/main';
import { AxiosResponse } from 'axios';
import { Notify } from 'quasar';
import { AreaResponse, BannerResponse, GPSAreaResponse } from './types';

@Module({ name: 'banner', namespaced: true })
export default class BannerModule extends VuexModule {
  public bannerData: BannerResponse | null = null;
  public areaData: AreaResponse | null = null;
  public detailBannerGroup: BannerResponse | null = null;
  public detailBanner: BannerResponse | null = null;
  public gpsArea: GPSAreaResponse | null = null;
  public visitedListData: any | null = null;
  public isAppDownloadClickData: boolean = false;

  @Mutation
  public setBannerData(response: BannerResponse) {
    this.bannerData = response;
  }

  @Mutation
  public setAreaData(response: AreaResponse) {
    this.areaData = response;
  }

  @Mutation
  public setDetailBannerGroup(response: BannerResponse) {
    this.detailBannerGroup = response;
  }

  @Mutation
  public setGPSArea(response: GPSAreaResponse) {
    this.gpsArea = response;
  }

  @Mutation
  public setDetailBanner(response: BannerResponse) {
    this.detailBanner = response;
  }

  @Mutation
  public setVisitedList(response: any) {
    this.visitedListData = response;
  }
  @Mutation
  public appDownloadClick(response: boolean) {
    this.isAppDownloadClickData = response;
  }

  @Action
  public appDownloadClickUpdate() {
    this.context.commit('appDownloadClick', true);
  }

  // 메인 베너 조회
  @Action
  public async fetchBanner(params) {
    // if (!payload || !payload.id) {
    //   return Promise.reject((app.$q.lang as any).msg.invalidRequest);
    // }
    try {
      const res: AxiosResponse<BannerResponse> = await axios.get(
        `/bannerGroup`,
        { params }
      );
      if (res && res.data) {
        this.context.commit('setBannerData', res.data);
      }
    } catch (error) {
      Notify.create({
        message: error || (app.$q.lang as any).msg.error
      });
    }
  }

  // 지역 필터 정보 조회
  @Action
  public async fetchArea() {
    try {
      const res: AxiosResponse<AreaResponse> = await axios.get(
        '/category/area'
      );
      if (res && res.data) {
        this.context.commit('setAreaData', res.data);
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 배너 그룹 더보기
  @Action
  public async fetchDetailBannerGroup(params) {
    try {
      const res: AxiosResponse<BannerResponse> = await axios.get(
        `bannerGroup/${params.id}`,
        { params }
      );
      if (res && res.data) {
        this.context.commit('setDetailBannerGroup', res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 단일 배너 조회
  @Action
  public async fetchDetailBanner(params) {
    try {
      const res: AxiosResponse<BannerResponse> = await axios.get(
        `banner/${params.id}`,
        { params }
      );
      if (res.data && res.data.status) {
        this.context.commit('setDetailBanner', res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 현재 위치로 지역 이름 조회
  @Action
  public async fetchGPSArea(params) {
    try {
      const res: AxiosResponse<GPSAreaResponse> = await axios.get(
        '/system/geoCode',
        { params }
      );
      if (res.data && res.data.status) {
        this.context.commit('setGPSArea', res.data);
        return res.data;
      } else {
        Notify.create({
          message: res.data.error.messge
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 최근 본 매장
  @Action
  public async fetchVisitedPlace(params: any) {
    try {
      const res = await axios.get('/place', { params });
      if (res.data && res.data.status) {
        this.context.commit('setVisitedList', res.data);
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  get getBanner() {
    if (!this.bannerData) {
      return null;
    }
    return this.bannerData.data;
  }

  get getArea() {
    if (!this.areaData) {
      return '';
    }
    return this.areaData.data;
  }

  get getDetailBannerGroup() {
    if (!this.detailBannerGroup) {
      return '';
    }
    return this.detailBannerGroup.data;
  }

  get getDetailBanner() {
    if (!this.detailBanner) {
      return '';
    }
    return this.detailBanner.data;
  }

  get getGPSArea() {
    if (!this.gpsArea) {
      return '';
    }
    return this.gpsArea.data;
  }

  get visitedList(): any {
    if (!this.visitedListData) {
      return '';
    }
    return this.visitedListData.data;
  }
  get isAppDownloadClick() {
    return this.isAppDownloadClickData;
  }
}
