// @ts-ignore
import packageJson from '../../package.json';
import Vue from 'vue';
import '../registerServiceWorker';

export const CONST = {
  APP_VERSION: packageJson.version,
  API_BASE_URL: process.env.VUE_APP_API_HOST_URL,
  API_BASE_URL_OS: process.env.VUE_APP_API_HOST_URL_OS,
  IS_DEVELOP: process.env.NODE_ENV === 'development',
  API_KEY: process.env.VUE_APP_API_KEY,
  API_KEY_OS: process.env.VUE_APP_API_KEY_OS,
  VUE_APP_FACEBOOK_ID: process.env.VUE_APP_FACEBOOK_ID,
  POING_AUTHORIZATION_NAME: process.env.VUE_APP_POING_AUTHORIZATION_NAME,
  POING_AUTHORIZATION_KEY: process.env.VUE_APP_POING_AUTHORIZATION_KEY,
  VUE_APP_NAVER_ID: process.env.VUE_APP_NAVER_ID,
  VUE_APP_NAVER_SECRET: process.env.VUE_APP_NAVER_SECRET,
  VUE_APP_NAVER_REDIRECT: process.env.VUE_APP_NAVER_REDIRECT
};

export const EventBus = new Vue();
