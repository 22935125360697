import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from '@/config/axios';
import { AxiosResponse } from 'axios';
import {
  CardInstallmentData,
  DetailReservationResponse,
  NearScheduleResponse,
  CalendarResponse,
  CapacityResponse,
  MenuResponse,
  AddOnInfoResponse
} from '@/store/reservation/types';

@Module({ name: 'reservation', namespaced: true })
export default class ReservationModule extends VuexModule {
  public detailReservation: any | null = null;
  public nearScheduleData: any | null = null;
  public calendarData: any | null = null;
  public capacityData: any | null = null;
  public menuData: any | null = null;
  public placeScheduleData: any | null = null;
  public productScheduleData: any | null = null;
  public placeTimeData: any | null = null;
  public productTimeData: any | null = null;
  public productReservationTimeData: any | null = null;
  public productCalendarData: any | null = null;
  public productOptionsData: any | null = null;
  public cartData: any | null = null;
  public reservationData: any | null = null;
  public reservationDeleteData: any | null = null;
  public cardInstallmentData: any | null = null;
  public reservationAddInfoData: any | null = null;

  @Mutation
  public setDetailReservation(response: DetailReservationResponse) {
    this.detailReservation = response;
  }

  @Mutation
  public setNearSchedule(response: NearScheduleResponse) {
    this.nearScheduleData = response;
  }

  @Mutation
  public setCalendar(response: CalendarResponse) {
    this.calendarData = response;
  }

  @Mutation
  public setCapacity(response: CapacityResponse) {
    this.capacityData = response;
  }

  @Mutation
  public setMenu(response: MenuResponse) {
    this.menuData = response;
  }

  @Mutation
  public setPlaceSchedule(response: any) {
    this.placeScheduleData = response;
  }

  @Mutation
  public setProductSchedule(response: any) {
    this.productScheduleData = response;
  }

  @Mutation
  public setPlaceTime(response: any) {
    this.placeTimeData = response;
  }

  @Mutation
  public setProductTime(response: any) {
    this.productTimeData = response;
  }
  @Mutation
  public setProductReservationTime(response: any) {
    this.productReservationTimeData = response;
  }
  @Mutation
  public setProductCalendar(response: any) {
    this.productCalendarData = response;
  }
  @Mutation
  public setProductOptions(response: any) {
    this.productOptionsData = response;
  }
  @Mutation
  public setReservationAddInfo(response: AddOnInfoResponse) {
    this.reservationAddInfoData = response;
  }

  @Mutation
  public setCart(response: any) {
    this.cartData = response;
  }

  @Mutation
  public setReservation(response: any) {
    this.reservationData = response;
  }

  @Mutation
  public setReservationDelete(response: any) {
    this.reservationDeleteData = response;
  }

  @Mutation
  public setCardInstallment(response: any) {
    this.cardInstallmentData = response;
  }

  // 예약 조회
  @Action
  public async fetchDetailReservation(id: any) {
    try {
      const res: AxiosResponse<DetailReservationResponse> = await axios.get(
        `/reservation/${id}`
      );
      if (res.status) {
        this.context.commit('setDetailReservation', res.data);
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * 가장 가까운 예약가능 시간대 조회
   * @param params
   */
  @Action
  public async fetchNearSchedule(params: any) {
    try {
      const res = await axios.get(`/reservation/nearSchedule`, {
        params: {
          place_id: params.place_id,
          date: params.date,
          people_count: params.person
        }
      });
      if (res.status) {
        this.context.commit('setNearSchedule', res.data);
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }

  @Action
  public async resetNearSchedule() {
    this.context.commit('setNearSchedule', null);
  }

  /**
   * 예약하기 캘린더 조회
   * @param params
   */
  @Action
  public async fetchCalendar(params: any) {
    try {
      const res = await axios.get(`/reservation/calendar`, {
        params: {
          place_id: params.place_id,
          table_type: params.table_type,
          ...(params.reservation_id && {
            reservation_id: params.reservation_id
          })
        }
      });
      if (res.status) {
        this.context.commit('setCalendar', res.data);
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }

  @Action
  public async resetCalendar() {
    this.context.commit('setCalendar', null);
  }

  @Action
  public async fetchCapacity(params: any) {
    try {
      const res = await axios.get(`/reservation/capacity`, {
        params: {
          place_id: params.place_id,
          reservation_date: params.reservation_date,
          table_type: params.table_type,
          reservation_id: params.reservation_id
        }
      });
      if (res.status) {
        this.context.commit('setCapacity', res.data);
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }

  @Action
  public async resetCapacity() {
    this.context.commit('setCapacity', null);
  }

  @Action
  public async fetchMenu(params: any) {
    try {
      const res = await axios.get('/reservation/menu', {
        params: {
          place_id: params.place_id,
          reservation_date: params.reservation_date,
          reservation_time: params.reservation_time,
          number_of_people: params.number_of_people,
          reservation_id: params.reservation_id
        }
      });
      if (res.status) {
        this.context.commit('setMenu', res.data);
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }

  @Action
  public async resetMenu() {
    this.context.commit('setMenu', null);
  }

  // 매장 예약 캘린더 조회
  @Action
  public async fetchPlaceSchedule(params: any) {
    try {
      const res = await axios.get(`/place/${params.id}/schedule`, {
        params: {
          date: params.date,
          person: params.person,
          reservation_id: params.reservation_id ? params.reservation_id : ''
        }
      });
      // const res = await axios.get(`/place/${params.id}/schedule`, { params });
      if (res.status) {
        this.context.commit('setPlaceSchedule', res.data);
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }

  // 캘리던 초기화
  @Action
  public async resetSchedule() {
    // timeSlot 초기화
    this.context.commit('setPlaceTime', null);
    this.context.commit('setProductReservationTime', null);
    // schedule 초기화
    this.context.commit('setPlaceSchedule', null);
    this.context.commit('setProductSchedule', null);
  }

  // 티켓 예약 캘린더 조회
  @Action
  public async fetchProductSchedule(params: any) {
    try {
      const res = await axios.get(`/product/${params.id}/schedule`, {
        params: {
          date: params.date,
          person: params.person,
          reservation_id: params.reservation_id ? params.reservation_id : ''
        }
      });
      // const res = await axios.get(`/product/${params.id}/schedule`, { params });
      if (res.status) {
        this.context.commit('setProductSchedule', res.data);
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }

  // 매장 상세 타임블럭
  @Action
  public async fetchPlaceTime(params: any) {
    try {
      const res = await axios.get(`/place/${params.id}/timeslot`, {
        params: {
          date: params.date,
          person: params.person
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setPlaceTime', res.data);
      }
      return res.data;
    } catch (error) {
      return null;
    }
  }

  // 티켓 상세 타임블럭
  @Action
  public async fetchProductTime(params: any) {
    try {
      const res = await axios.get(`/product/${params.id}/timeslot`, {
        params: {
          date: params.date,
          person: params.person
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setProductTime', res.data);
      }
      return res.data;
    } catch (error) {
      return null;
    }
  }
  // 티켓 예약날짜별 타임블럭
  @Action
  public async fetchProductReservationTime(params: any) {
    try {
      const res = await axios.get(`/reservation/exclusiveCapacity`, {
        params: {
          product_id: params.product_id,
          reservation_date: params.reservation_date,
          except_person: params.except_person
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setProductReservationTime', res.data);
      }
      return res.data;
    } catch (error) {
      return null;
    }
  }
  // 티켓 예약 캘린더 조회
  @Action
  public async fetchProductCalendar(params: any) {
    try {
      const res = await axios.get(`/reservation/exclusiveCalendar`, {
        params: {
          product_id: params.product_id
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setProductCalendar', res.data);
      }
      return res.data;
    } catch (error) {
      return null;
    }
  }
  // 티켓 옵션 조회
  @Action
  public async fetchProductOptions(params: any) {
    try {
      const res = await axios.get(`/reservation/exclusiveMenu`, {
        params: {
          product_id: params.product_id,
          reservation_date: params.reservation_date,
          reservation_time: params.reservation_time,
          person: params.person
        }
      });
      if (res && res.data && res.data.data) {
        this.context.commit('setProductOptions', res.data);
      }
      return res.data;
    } catch (error) {
      return null;
    }
  }
  // 비회원 인증번호 발송
  @Action
  public async postRequestNumSend(params: any) {
    try {
      const res = await axios.post(`/user/sendCertifyNumber`, params);
      if (res && res.data && res.data.data) {
        // this.context.commit('', res.data);
        return res.data;
      }
      return res.data;
    } catch (error) {
      return null;
    }
  }
  // 비회원 인증번호 검증(확인)
  @Action
  public async putRequestNum(params: any) {
    try {
      const res = await axios.put(`/user/verifyCertifyNumber`, params);
      if (res && res.data && res.data.data) {
        // this.context.commit('', res.data);
        return res.data;
      }
      return res.data;
    } catch (error) {
      return error;
    }
  }
  // 예약시 추가 정보 (기념일, 알러지 등...)
  @Action
  public async fetchReservationAddInfo(payload: any) {
    try {
      const res = await axios.get(
        `/place/${payload.place_id}/reserveAddOnInfo`
      );
      if (res && res.data && res.data.data) {
        this.context.commit('setReservationAddInfo', res.data);
        return res.data;
      }
      return res.data;
    } catch (error) {
      return error;
    }
  }

  // 티켓 예약
  @Action
  public async fetchCart(payload: any) {
    try {
      const res = await axios.post('/cart', payload);
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }

  // 티켓 예약 정보 조회
  @Action
  public async fetchCartData(id) {
    try {
      const res = await axios.get(`/cart/${id}`);
      if (res && res.data) {
        this.context.commit('setCart', res.data);
        return res.data;
      }
    } catch (e) {
      return false;
    }
  }

  // 결제 페이지에서 취소했을 경우
  @Action
  public async deleteCart(id) {
    try {
      const res = await axios.delete(`/cart/${id}`);
      if (res && res.data) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  // 예약 접수
  @Action
  public async fetchReservationRegister(payload: any) {
    try {
      const res = await axios.post(`/reservation`, payload);
      if (res && res.data) {
        this.context.commit('setReservation', res.data);
      }
      return res.data;
    } catch (err) {
      return err;
    }
  }

  // 예약 변경
  @Action
  public async fetchReservationChange(params: any) {
    try {
      // if (params.message === '') {
      //   delete params.message;
      // }
      const res = await axios.put(
        `/reservation/${params.reservationId}`,
        params
      );
      if (res.status) {
        return res.data;
      }
    } catch (e) {
      return null;
    }
  }

  // 예약 취소
  @Action
  public async fetchReservationDelete(reservationId: any) {
    try {
      const res = await axios.delete(`/reservation/${reservationId}`);
      console.log(res);
      if (res.status) {
        this.context.commit('setReservationDelete', res.data);
      }
      return res.data;
    } catch (e) {
      return e.data;
    }
  }

  // 결제 취소
  // @Action
  // public async fetchTicketDelete(params) {
  //   try {
  //     const res = await axios.delete(`/reservation/${params.id}`, {
  //       params: {
  //         refund_rate: params.refund_rate
  //       }
  //     });
  //     return res.data;
  //   } catch (e) {
  //     return e.data;
  //   }
  // }

  // 카드별 무이자 할부 정보 조회
  @Action
  public async fetchCardInstallment() {
    try {
      const res = await axios.get(`/order/quota`);
      if (res && res.status) {
        this.context.commit('setCardInstallment', res.data);
        return res.data;
      }
    } catch (e) {
      return false;
    }
  }

  get getDetailReservation() {
    if (!this.detailReservation) {
      return [];
    }
    return this.detailReservation.data;
  }

  get nearSchedule() {
    if (!this.nearScheduleData) {
      return '';
    } else {
      return this.nearScheduleData.data;
    }
  }

  get calendar() {
    if (!this.calendarData) {
      return '';
    } else {
      return this.calendarData.data;
    }
  }

  get capacity() {
    if (!this.capacityData) {
      return '';
    } else {
      return this.capacityData.data;
    }
  }

  get menu() {
    if (!this.menuData) {
      return '';
    } else {
      return this.menuData.data;
    }
  }

  get placeSchedule() {
    if (!this.placeScheduleData) {
      return '';
    }
    return this.placeScheduleData.data;
  }

  get productSchedule() {
    if (!this.productScheduleData) {
      return '';
    }
    return this.productScheduleData.data;
  }

  get placeTime() {
    if (!this.placeTimeData) {
      return '';
    } else {
      return this.placeTimeData.data;
    }
  }

  get productTime() {
    if (!this.productTimeData) {
      return '';
    } else {
      return this.productTimeData.data;
    }
  }
  get productReservationTime() {
    if (!this.productReservationTimeData) {
      return '';
    } else {
      return this.productReservationTimeData.data;
    }
  }
  get productCalendar() {
    if (!this.productCalendarData) {
      return '';
    } else {
      return this.productCalendarData.data;
    }
  }
  get productOptions() {
    if (!this.productOptionsData) {
      return '';
    } else {
      return this.productOptionsData.data;
    }
  }
  get reservationAddInfo() {
    if (!this.reservationAddInfoData) {
      return '';
    } else {
      return this.reservationAddInfoData.data;
    }
  }

  get cartInfo() {
    if (!this.cartData) {
      return '';
    } else {
      return this.cartData.data;
    }
  }

  get reservation() {
    if (!this.reservationData) {
      return '';
    }
    return this.reservationData.data;
  }

  get getCardIntallment() {
    if (!this.cardInstallmentData) {
      return '';
    }
    return this.cardInstallmentData.data;
  }
}
