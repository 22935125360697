import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from '@/config/axios';
import app from '@/main';
import { AxiosResponse } from 'axios';
import { Notify } from 'quasar';
import {
  CommentResponse,
  DetailReviewResponse,
  ReviewLikerResponse,
  ReviewReportResponse,
  ReviewResponse,
  UserReviewListResponse
} from './types';
import { unionWith } from 'lodash';

@Module({ name: 'review', namespaced: true })
export default class ReviewModule extends VuexModule {
  public userReviewData: UserReviewListResponse | null = null;
  public detailReview: DetailReviewResponse | null = null;
  public comment: CommentResponse | null = null;
  public reviewLiker: ReviewLikerResponse | null = null;

  @Mutation
  public setUserReview(response: UserReviewListResponse) {
    // 데이터가 없는 초기값
    if (response.meta.offset === 1) this.userReviewData = null;
    if (!this.userReviewData || !this.userReviewData.data.length) {
      this.userReviewData = response;
    } else {
      // 데이터가 있는 경우 기존과 병합
      this.userReviewData.data = unionWith(
        this.userReviewData.data,
        response.data,
        (a, b) => {
          return a.id === b.id;
        }
      );
      // meta
      this.userReviewData.meta = response.meta;
    }
  }

  @Mutation
  public setReviewLiker(response: ReviewLikerResponse) {
    // 데이터가 없는 초기값
    if (response.meta.offset === 1) this.userReviewData = null;
    if (!this.reviewLiker || !this.reviewLiker.data.length) {
      this.reviewLiker = response;
    } else {
      // 데이터가 있는 경우 기존과 병합
      this.reviewLiker.data = unionWith(
        this.reviewLiker.data,
        response.data,
        (a, b) => {
          return a.id === b.id;
        }
      );
      // meta
      this.reviewLiker.meta = response.meta;
    }
  }

  @Mutation
  public setDetailReview(response: DetailReviewResponse) {
    this.detailReview = response;
  }

  @Mutation
  public setCommentData(response: CommentResponse) {
    if (response.meta.offset === 1) {
      this.comment = null;
    }
    // 데이터가 없는 초기값
    if (!this.comment || !this.comment.data.length) {
      this.comment = response;
    } else {
      // 데이터가 있는 경우 기존과 병합
      this.comment.data = unionWith(
        this.comment.data,
        response.data,
        (a: any, b: any) => {
          return a.id === b.id;
        }
      );
      // meta
      this.comment.meta = response.meta;
    }
  }

  // 마이포잉 리뷰 불러오기
  @Action
  public async fetchUserReview(params) {
    try {
      const res: AxiosResponse<ReviewResponse> = await axios.get('/review', {
        params
      });
      if (res && res.data) {
        this.context.commit('setUserReview', res.data);
      }
      return Promise.resolve(res && res.data);
    } catch (error) {
      Notify.create({
        message: error.message
      });
    }
  }

  // 리뷰 상세
  @Action
  public async fetchDetailReview(id) {
    try {
      const res: AxiosResponse<DetailReviewResponse> = await axios.get(
        `/review/${id}`
      );
      if (res && res.data) {
        this.context.commit('setDetailReview', res.data);
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 리뷰 댓글 조회
  @Action
  public async fetchReviewComment(params) {
    try {
      const res: AxiosResponse<CommentResponse> = await axios.get('/comment', {
        params
      });
      if (res && res.data) {
        this.context.commit('setCommentData', res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 리뷰 등록
  @Action
  public async AddReview(payload) {
    try {
      const res = await axios.post('/review', payload);
      if (res && res.data) {
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 리뷰 수정
  @Action
  public async updateReview({ id, payload }) {
    try {
      const res = await axios.put(`/review/${id}`, payload);
      if (res && res.data) {
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 리뷰 삭제
  @Action
  public async deleteReview(id) {
    try {
      const res = await axios.delete(`/review/${id}`);
      if (res && res.data) {
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 댓글 등록
  @Action
  public async addComment(payload) {
    try {
      const res = await axios.post('/comment', payload);
      if (res && res.data) {
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 댓글 수정
  @Action
  public async updateComment({ id, payload }) {
    try {
      const res = await axios.put(`/comment/${id}`, payload);
      if (res && res.data) {
        return res.data;
      } else return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  // 댓글 삭제
  @Action
  public async deleteComment(id) {
    try {
      const res = await axios.delete(`/comment/${id}`);
      if (res && res.data) {
        return res.data;
      } else return res.data;
    } catch (e) {
      console.log(e);
    }
  }

  // 리뷰 신고
  @Action
  public async ReviewReport(payload) {
    try {
      const res: AxiosResponse<ReviewReportResponse> = await axios.post(
        '/report',
        payload
      );
      console.log(res);
      if (res && res.data) {
        return res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 리뷰 좋아요한 유저 리스트 조회
  @Action
  public async fetchReviewLiker(params) {
    try {
      const res: AxiosResponse<ReviewLikerResponse> = await axios.get(
        `/review/${params.id}/likeUsers?offset=${params.offset}&limit=${params.limit}`
      );
      if (res && res.data) {
        this.context.commit('setReviewLiker', res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  get userReview() {
    if (!this.userReviewData) {
      return null;
    }
    return this.userReviewData.data;
  }

  get userReviewMeta() {
    if (!this.userReviewData) {
      return false;
    }
    return this.userReviewData.meta;
  }

  get getDetailReview() {
    if (!this.detailReview) {
      return '';
    }
    return this.detailReview.data;
  }

  get getComment() {
    if (!this.comment) {
      return '';
    }
    return this.comment;
  }

  get getReviewLiker() {
    if (!this.reviewLiker) {
      return '';
    }
    return this.reviewLiker;
  }
}
