import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from '@/config/axios';
import { Notify } from 'quasar';

@Module({ name: 'like', namespaced: true })
export default class LikeModule extends VuexModule {
  // 좋아요
  @Action
  public async fetchLike(payload: any) {
    try {
      const res = await axios.post('/like', payload);
      // const res = await axios.post(
      //   `/like?target=${payload.target}&target_id=${payload.target_id}`,
      //   {
      //     payload
      //   }
      // );
      if (res && res.data && res.data.data) {
        return res.data;
      }

      // 202 error
      if (res && res.data && res.data.error) {
        Notify.create({
          message: res.data.error.message
        });
      }
    } catch (error) {
      return null;
    }
  }
  // 좋아요 취소
  @Action
  public async fetchLikeDelete(payload: any) {
    try {
      const res = await axios.delete('/like', { params: payload });
      if (res && res.data && res.data.data) {
        return res.data;
      }
      // 202 error
      if (res && res.data && res.data.error) {
        Notify.create({
          message: res.data.error.message
        });
      }
    } catch (error) {
      return null;
    }
  }
}
