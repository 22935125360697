






import { Vue, Component } from 'vue-property-decorator';
import MainHeader from '@/components/layout/MainHeader.vue';

@Component({ components: { MainHeader } })
export default class App extends Vue {
  public mounted() {
    this.cssHeight();
  }
  public cssHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    })
    /**
     * css 사용
     * height: 100vh
     * height: calc(var(--vh, 1vh) * 100);
     */
  }
}
