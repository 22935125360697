import axios from 'axios';
import { CONST } from '@/config/config';

const axiosOS = axios.create();

axiosOS.defaults.baseURL = CONST.API_BASE_URL_OS;
axiosOS.defaults.headers.common['x-api-key'] = CONST.API_KEY_OS;

axiosOS.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosOS.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 400 error
    if (error.response) {
      return Promise.reject(error.response);
    }
  }
);

export default axiosOS;
